import React, { useEffect, useCallback } from "react";
import { VariantType, useSnackbar } from "notistack";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { removeAlert } from "../../redux/appSetting/actions";
import Fade from "@mui/material/Fade";
import CustomAlert from "./CustomAlert";
import { AppContext } from "App";

export interface AlertTypes {
  duration?: number;
  msg?: string;
  variant?: VariantType;
  trigger?: boolean;
}
interface RootState {
  config: object;
}

// This used to memorize the selector value
const ConfigSelector = createSelector(
  (state: RootState) => state.config,
  (alert: any) => alert
);

const Alert: React.FC<AlertTypes> = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const [t, theme] = React.useContext(AppContext);
  const alretReducer: AlertTypes = useSelector(ConfigSelector);
  const handleClickVariant = useCallback(() => {
    enqueueSnackbar(alretReducer.msg, {
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
      autoHideDuration: 2000,
      TransitionComponent: Fade,
      content: (key:number, message:string) => (
        <CustomAlert
          id={key}
          message={message||""}
          variant={alretReducer.variant as VariantType | string}
          theme={theme}
        />
      ),
    });
  }, [enqueueSnackbar, alretReducer]);

  const alertCall = useCallback(() => {
    handleClickVariant();
    dispatch(removeAlert());
  }, [dispatch, handleClickVariant]);

  useEffect(() => {
    if (alretReducer.trigger) {
      alertCall();
    }
  }, [alretReducer, alertCall]);

  return <div>{""}</div>;
};

export default Alert;
