import { IntialStateReports } from "./type";
import { GET_REPORTS, GET_REPORT } from "./types";


const initialState: IntialStateReports = {
  reports: [],
  currentReport: null
};

export default function reportsReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;

  switch (type) {
    case GET_REPORTS: {
      return {
        ...state,
        reports: payload
      };
    }
    case GET_REPORT: {
      return {
        ...state,
        currentReport: payload
      };
    }

    default:
      return state;
  }
}
