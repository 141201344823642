import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  AlertTitle,
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { AppContext } from "App";
import { ReactComponent as InstallAppIos } from "assets/general-icon/installAppIos.svg";
import { ReactComponent as ShareBtnIos } from "assets/general-icon/shareBtnIos.svg";
import { AppConfig } from "enums/config";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

type Props = {};

const InstallIosPrompt: React.FC<Props> = () => {
  const [t, theme, , , , mobileMatch] = useContext(AppContext);

  const checkForIOS = () => {
    if ((window.navigator as any)?.standalone) {
      return { prompt: false };
    }
    const today = moment().toDate();
    const lastPrompt = moment(localStorage.getItem("installPrompt"));
    const days = moment(today).diff(lastPrompt, "days");
    const ua = window.navigator.userAgent;
    const webkit = !!ua.match(/WebKit/i);
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i);
    const isIOS = isIPad || isIPhone;
    const isSafari = isIOS && webkit && !ua.match(/CriOS/i);

    const prompt = /* (isNaN(days) || days > 1) && */ isIOS && isSafari;

    if (prompt && "localStorage" in window) {
      localStorage.setItem("installPrompt", today.toString());
    }

    return { isIOS, isSafari, prompt };
  };
  const [showInstallPrompt, setShowInstallPrompt] = useState<boolean>(false);

  useEffect(() => {
    setShowInstallPrompt(checkForIOS().prompt);
  }, []);

  const handleClickAway = () => {
    setShowInstallPrompt(false);
  };

  return showInstallPrompt ? (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Collapse in={showInstallPrompt}>
        <Alert
          icon={
            <DownloadRoundedIcon
              fontSize="medium"
              sx={{ color: theme.palette.primary.main }}
            />
          }
          sx={{
            mt: 6,
            bgcolor: theme.palette.whatsApp.recieverColor,
            borderRadius: AppConfig.BORDER_RADIUS_MIN,
            boxShadow: theme.palette.general.boxShadowWOW,
            position: "absolute",
            zIndex: 1000000,
            width: mobileMatch ? "90%" : "50%",
            maxWidth: "600px",
            left: 0,
            right: 0,
            mr: "auto",
            ml: "auto",
            color: theme.palette.general.boxShadowDark,
          }}
          action={
            <IconButton
              sx={{
                color: theme.palette.primary.main,
              }}
              color="inherit"
              size="medium"
              onClick={handleClickAway}
            >
              <ClearRoundedIcon />
            </IconButton>
          }
        >
          <AlertTitle color={theme.palette.primary.main}>
            {t("install_our_app")}
          </AlertTitle>
          <Grid display={"flex"} alignItems="center" my={"0.5em"}>
            {t("install_share_prompt")}
            <ShareBtnIos />
          </Grid>
          <Grid display={"flex"} alignItems="center">
            {t("install_ios_prompt")}
            <InstallAppIos height={20} />
          </Grid>
        </Alert>
      </Collapse>
    </ClickAwayListener>
  ) : null;
};

export default InstallIosPrompt;
