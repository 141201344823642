import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Direction, Hidden, PaletteMode } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as Sentry from "@sentry/react";
import InstallIosPrompt from "coreUI/constant/InstallIosPromt";
import UpdateAlert from "coreUI/constant/UpdateAlert";
import { isEmpty } from "lodash";
import { SnackbarProvider } from "notistack";
import React, {
  createContext,
  Profiler,
  Suspense,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { addToken } from "redux/auth/actions";
import { authSelector, UserProfile } from "redux/auth/selector";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import Alert from "utils/Alert";
import { IframeCheck } from "utils/helper";
import "./app.css";
import MobileNavbarContainer from "./components/mobile-navbar/MobileNavbarContainer";
import Loader from "./coreUI/loader/Loader";
import { clearSelectedSession } from "./redux/session/actions";
import { themeConfig } from "./styles/styleTheme";

// import { Theme } from "@mui/material/styles";
const NavigationContainer = React.lazy(() => import("./navigation"));

export const AppContext = createContext<any>([]);
// export const controller = new AbortController();
function App() {
  const [mode, setMode] = useState<PaletteMode>("light");
  const [direction, setDirection] = useState<Direction>("ltr");
  const dispatch = useDispatch();
  const { token }: UserProfile = useSelector(authSelector);
  const [windowLocation, setWindowLocation] = useState<string>("");

  const colorMode = () => {
    setMode((prevMode: PaletteMode) =>
      prevMode === "light" ? "dark" : "light"
    );
  };
  // Create rtl cache
  const cacheRtl = useMemo(() => {
    document.body.dir = direction;
    if (direction === "rtl") {
      return createCache({
        key: "muirtl",
        stylisPlugins: [prefixer, rtlPlugin],
      });
    } else {
      return createCache({ key: "css" });
    }
  }, [direction]);

  const { t } = useTranslation();
  const theme: any = useMemo(
    () => createTheme(themeConfig(mode, direction)),
    [mode, direction]
  );
  const [tabValue, setTabValue] = useState<string>("1");

  const largPcMatch: boolean = useMediaQuery(theme.breakpoints.down("xl")); // 1800px
  const minPcMatch: boolean = useMediaQuery(theme.breakpoints.down("lg")); // 1290px
  const smallPcMatch: boolean = useMediaQuery(theme.breakpoints.down("md")); // 1174px
  const mobileMatch: boolean = useMediaQuery(theme.breakpoints.down("sm")); //894px

  const mobileNavRef = useRef(null);

  const handleSetTabValue = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
    dispatch(clearSelectedSession());
  };

  useEffect(() => {
    if (token === "") {
      dispatch(addToken());
    }
  }, []);

  // read the url from iframe
  // when our app used with IFrame, read the query on it
  useEffect(() => {
    let url = window.location.search;
    url = url.substring(1);
    let querySum = url.split("&");
    let queryMap: { token: string; search: string } = {
      token: "",
      search: "",
    };
    // console.log({url,querySum})
    if (url.includes("token")) {
      //   let querySum = url.split("&");
      for (let i = 0; i < querySum.length; i++) {
        let singleQuery = querySum[i].split("=");
        queryMap = {
          ...queryMap,
          [singleQuery[0]]: singleQuery[1],
        };
      }
      localStorage.setItem("token", queryMap["token"]);
      localStorage.setItem("search", queryMap["search"]);
      dispatch(addToken());
    }
  }, []);

  const value = useMemo(
    () => [
      t,
      theme,
      minPcMatch,
      smallPcMatch,
      largPcMatch,
      mobileMatch,
      mobileNavRef,
      tabValue,
      handleSetTabValue,
      setTabValue,
      setDirection,
    ],
    [
      t,
      theme,
      minPcMatch,
      smallPcMatch,
      largPcMatch,
      mobileMatch,
      mobileNavRef,
      tabValue,
      handleSetTabValue,
      setTabValue,
      setDirection,
    ]
  );
  // This used to measure the performance of the component
  function onRenderCallback(
    id: any, // the "id" prop of the Profiler tree that has just committed
    phase: any, // either "mount" (if the tree just mounted) or "update" (if it re-rendered)
    actualDuration: any, // time spent rendering the committed update
    baseDuration: any, // estimated time to render the entire subtree without memoization
    startTime: any, // when React began rendering this update
    commitTime: any, // when React committed this update
    interactions: any // the Set of interactions belonging to this update
  ) {
    // Aggregate or log render timings...
    // console.log({
    //   id,
    //   phase,
    //   actualDuration,
    //   baseDuration,
    //   startTime,
    //   commitTime,
    //   interactions
    // })
  }
  //todo Remove Profiler in production This just a performance watcher
  return (
    <Profiler id="my-app" onRender={onRenderCallback}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={theme}>
          <>
            <SnackbarProvider
              maxSnack={4}
              anchorOrigin={{
                horizontal: theme.direction === "ltr" ? "right" : "left",
                vertical: "bottom",
              }}
              disableWindowBlurListener={true}
              preventDuplicate
              dense
            >
              <AppContext.Provider value={value}>
                <UpdateAlert />
                <InstallIosPrompt />
                <Alert />
                <div>
                  <Suspense
                    fallback={
                      <Loader
                        loaderStyle={{
                          display: "flex",
                          position: "absolute",
                          top: "50%",
                          [theme.direction === "ltr" ? "left" : "right"]: "50%",
                        }}
                      />
                    }
                  >
                    <Router>
                      {windowLocation !== "completeRegister" &&
                        !isEmpty(token) &&
                        isEmpty(IframeCheck()) && (
                          <Hidden lgUp={true}>
                            <MobileNavbarContainer navRef={mobileNavRef} />
                          </Hidden>
                        )}
                      <Switch>
                        <NavigationContainer />
                      </Switch>
                    </Router>
                  </Suspense>
                </div>
              </AppContext.Provider>
            </SnackbarProvider>
          </>
        </ThemeProvider>
      </CacheProvider>
    </Profiler>
  );
}

export default Sentry.withProfiler(App);
