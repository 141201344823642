import authService, { signUp, verifySignUp } from "../../services/auth.service";
import { AxiosResponse } from "axios";
import {
  authenticationEvent,
  changeProfileStatus,
  loginStatus,
  logoutEvent,
  logoutNotification,
  profileStatus,
  reloginEvent,
  socketDisconnected,
} from "../../socketConfig/socketEvent";
import {
  LOGIN_STATUS,
  GET_PROFILE,
  ADD_TOKEN,
  USER_LOGOUT,
  USER_SIGNUP,
  USER_VERIFY,
  RESET_REGIST,
  FORGET_PASSWORD,
  RESET_PASSWORD,
  OTHER_LOGOUT,
  DISABLE_CURRENT_TAB_SESSION,
  UPDATE_PROFILE_STATUE,
} from "./types";
import { loading, stopLoading, triggerAlert } from "../appSetting/actions";
import localize from "../../utils/localize";
import { socket } from "socketConfig/socketConnect";
// import { toString } from "lodash";
import { controller } from "config/httpComman";
import { errorHandling } from "utils/errorHandling";
// import { controller } from "App";

export const loginSocketConfirm = (jwt: string) => async (dispatch: any) => {
  try {
    authenticationEvent(jwt);

    // check loginEvent
    loginStatus((data: { status: "SUCCESS" }) => {
      if (data.status === "SUCCESS") {
        localStorage.setItem("socketId", socket.id);
        dispatch(addSocketId(data));
        return;
      } else {
        socket.disconnect(true);
        dispatch(triggerAlert(localize("login_failed"), "error"));
      }
    });
  } catch (error) {
    console.log({ loadSessionsError: error });
  }
};

export const addSocketId =
  (data: { status: "SUCCESS" }) => async (dispatch: any) => {
    try {
      if (data.status === "SUCCESS") {
        dispatch({
          type: LOGIN_STATUS,
          payload: {
            loginStatus: data,
            socketId: socket.id ?? localStorage.getItem("socketId"),
          },
        });
      }
    } catch (e) {
      dispatch(triggerAlert(localize("logout_failed"), "error"));
    }
  };

export const gettingProfile = () => async (dispatch: any) => {
  try {
    const response: AxiosResponse = await authService.getProfile();
    if (response.status === 200) {
      dispatch({
        type: GET_PROFILE,
        payload: { profile: response.data },
      });
    }
  } catch (er: any) {
    errorHandling(er, dispatch, "login_failed");
  }
};

export const login = (user: any) => async (dispatch: any) => {
  try {
    const response: AxiosResponse = await authService.postLogin(user);
    if (response.status === 200 && response.data.hasOwnProperty("token")) {
      localStorage.setItem("token", response.data.token);
      //   localStorage.setItem("location", '/dashboard');
      dispatch(addToken());
      return response;
    }
    dispatch(triggerAlert(response?.data?.toString() || "", "error"));
  } catch (e: any) {
    // console.log({e})
    dispatch(triggerAlert(e?.response?.data?.failMsg.toString(), "error"));
  }
};

export const addToken = () => async (dispatch: any) => {
  try {
    dispatch({
      type: ADD_TOKEN,
      payload: { token: localStorage.getItem("token") },
    });
  } catch (e) {
    dispatch(triggerAlert(localize("logout_failed"), "error"));
  }
};

export const logoutTabInstances = () => async (dispatch: any) => {
  try {
    logoutNotification(() => {
      dispatch({
        type: OTHER_LOGOUT,
      });
    });
  } catch (error) {
    console.log({ loadSessionsError: error });
  }
};
export const logout = (token: string) => async (dispatch: any) => {
  try {
    // console.log('logout')
    localStorage.removeItem("token");
    localStorage.removeItem("socketId");
    logoutEvent();
    socket.disconnect(true);
    dispatch({
      type: USER_LOGOUT,
    });
    controller.abort();
    window.location.reload();
    // localStorage.setItem('location','/');
  } catch (e) {
    dispatch(triggerAlert(localize("logout_failed"), "error"));
  }
};

export const reloginAction = () => async (dispatch: any) => {
  try {
    reloginEvent((data: { status: "SUCCESS" }) => {
      if (data.status === "SUCCESS") {
        dispatch({
          type: DISABLE_CURRENT_TAB_SESSION,
        });
        localStorage.removeItem("socketId");
        socket.disconnect(true);
        controller.abort();
      }
    });
  } catch (e) {
    dispatch(triggerAlert(localize("logout_failed"), "error"));
  }
};

export const updatingProfileStatus =
  (status: boolean) => async (dispatch: any) => {
    try {
      await changeProfileStatus(status);
    } catch (er: any) {
      errorHandling(er, dispatch, "agent_status_failed");
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateProfileStatus = () => async (dispatch: any) => {
  try {
    profileStatus(
      (data: { message: string; status: string; visibility: boolean }) => {
        if (data.status === "SUCCESS") {
          dispatch({
            type: UPDATE_PROFILE_STATUE,
            payload: !data.visibility,
          });
        }
      }
    );
  } catch (er: any) {
    errorHandling(er, dispatch, "agent_status_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const register = (data: signUp) => async (dispatch: any) => {
  try {
    dispatch(loading());

    await authService.signUp(data);
    dispatch({
      type: USER_SIGNUP,
      payload: data,
    });
    dispatch(triggerAlert(localize("signup_success"), "success"));
  } catch (er: any) {
    errorHandling(er, dispatch, "signup_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const resetRegister = () => (dispatch: any) => {
  dispatch({
    type: RESET_REGIST,
  });
};

export const verifyRegister = (data: verifySignUp) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await authService.verifySignup(data);
    dispatch({
      type: USER_VERIFY,
      payload: response,
    });
    dispatch(triggerAlert(localize("verify_success"), "success"));
  } catch (er: any) {
    errorHandling(er, dispatch, "verify_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const forgetPassword = (email: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await authService.forgetPassword(email);
    dispatch({
      type: FORGET_PASSWORD,
      payload: response,
    });
    dispatch(triggerAlert(localize("forget_password_success"), "success"));
  } catch (er: any) {
    errorHandling(er, dispatch, "forget_password_failed");
  } finally {
    dispatch(stopLoading());
  }
};

export const resetPassword =
  (data: { password: string; resetKey: string }) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await authService.resetPassword(data);
      dispatch({
        type: RESET_PASSWORD,
        payload: response,
      });
      dispatch(triggerAlert(localize("reset_password_success"), "success"));
      window.location.href = `${process.env.REACT_APP_HOST}`;
    } catch (er: any) {
      errorHandling(er, dispatch, "reset_password_failed");
    } finally {
      dispatch(stopLoading());
    }
  };
