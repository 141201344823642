import { channelsProps, engineProps } from "./type";

export const updateChannels = ({
  engines,
  channels
}: { engines: engineProps[], channels: channelsProps[] }) => {
  if (engines && engines.length > 0) {
    engines.map((engine: engineProps) => {
      const res:channelsProps|undefined = channels.find((item: channelsProps) => item.enginesType===engine.engineType)
      if (res && res!==undefined) {
        res.icon = engine.icon
      }
    })
  }
  return channels;
};
