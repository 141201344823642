import { typeVariable } from "enums/flow";
import { filter, findIndex, has, includes, intersection, isEmpty, keys, last, map } from "lodash";

export const handleCorrectNodes = (
  targetValidations: {
    [nodeId: string]: number;
  },
  orderedNodesObj: { [nodeId: string]: { index: number; parentId: string } } = {}
) => {
  let lastItem = last(keys(targetValidations)) || "";
  let infoLastItem = orderedNodesObj?.[lastItem] || {index:0,parentId:"0"};
  // in this case the targetValidations has parentId with value zero, so should exit
  if (isEmpty(infoLastItem)) {
    return null;
  }
  Object.assign(targetValidations, {
    [infoLastItem?.parentId || ""]: infoLastItem?.index,
  });
  if (infoLastItem?.index === 0 && infoLastItem?.parentId === "0") {
    return null;
  }
  handleCorrectNodes(targetValidations);
};

export const handleVarFound = (
  targetNodeID: string,
  resOrderedNodes: {
    [nodeId: string]: { index: number; parentId: string };
  } = {},
  nodesIdArr: string[]
) => {
  let idsNodes: string[] = keys(resOrderedNodes);
  let index: number = findIndex(idsNodes, (nd) => nd === targetNodeID);
  let targetIdsNodes: string[] = idsNodes.splice(0, index + 1).reverse();

  let commonElements = intersection(nodesIdArr, targetIdsNodes) || [];
  let isNotFound: boolean = true;
  if (commonElements.length > 0) {
    let correctNodes: { [nodeId: string]: number } = {
      [resOrderedNodes?.[targetNodeID]?.parentId]:
        resOrderedNodes?.[targetNodeID]?.index || 0,
    };
    handleCorrectNodes(correctNodes, resOrderedNodes);
    map(commonElements, (i) => {
      if (
        has(correctNodes, resOrderedNodes?.[i]?.parentId) &&
        correctNodes[resOrderedNodes?.[i]?.parentId] ===
        resOrderedNodes?.[i]?.index
      ) {
        isNotFound = false;
      }
    });
  } else {
    isNotFound = true;
  }
  return isNotFound;
};

// return array of nodes that had errors related to variables and after updating the variables,these errors became not correct  and must cancel it

export const getNodesHadeErrorsRelatedVars = (
  userSpaceVars: any,
  targetNodeId: string,
  orderedNodes: any = {},
  nodesConnectedWithSystemVars: any = {},
  errorsRelatedVariables: any = {}

) => {
  let targetCorrectNodes: any = {};
  map(userSpaceVars, (userSpaceVar) => {
    let nodeConnectedWithVar: any = {};
    if (userSpaceVar?.type === typeVariable.ARRAY) {
      nodeConnectedWithVar = nodesConnectedWithSystemVars?.[userSpaceVar?.title]?.[
        userSpaceVar?.type
      ]?.[JSON.stringify(userSpaceVar?.arrKeys)];
    } else {
      nodeConnectedWithVar =
        nodesConnectedWithSystemVars?.[userSpaceVar?.title]?.[
        userSpaceVar?.type
        ];
    }
    map(keys(nodeConnectedWithVar), (nId) => {
      if (errorsRelatedVariables?.[nId]) {
        let correctNodes: { [nodeId: string]: number } = {
          [orderedNodes?.[nId]?.parentId]:
            orderedNodes?.[nId]?.index || 0,
        };
        handleCorrectNodes(correctNodes, orderedNodes);
        if (
          has(correctNodes, orderedNodes?.[targetNodeId]?.parentId) &&
          correctNodes[orderedNodes?.[targetNodeId]?.parentId] ===
          orderedNodes?.[targetNodeId]?.index
        ) {
          targetCorrectNodes[nId] = nodeConnectedWithVar[nId];
        }
      }
    });
  });

  return targetCorrectNodes;
};

export const onExtractErrors = (nodesHadeErrorVar: any, newErrorsArr: any = {}) => {
  map(keys(nodesHadeErrorVar), (nId) => {
    newErrorsArr = {
      ...newErrorsArr,
      [nId]: {
        ...newErrorsArr?.[nId],
        rulesId: filter(
          newErrorsArr?.[nId]?.rulesId,
          (i) => !includes(nodesHadeErrorVar[nId], i)
        ),
      },
    };
    if (newErrorsArr?.[nId]?.rulesId?.length === 0) {
      delete newErrorsArr?.[nId];
    }
  });
  return newErrorsArr;
};
