export enum TemplateButtonsType {
    CALL_ACTIONS = "call-actios",
    QUICKY_REPLY = "quicky-reply",
}

export enum ReplyNodeHeaderType {
    TEXT = "text",
    IMAGE = "image",
    VIDEO = "video",
    DOCUMENT = "document",
}

export enum timeType {
    seconds = "seconds",
    minutes = "minutes",
    hours = "hours",
    days = "days",
    months = "months"
}
export enum varsSource {
    CSV = "csv",
    EXPRESSION = "expression",
    VALUE = "value",
}

export enum ReplySettings {
    TEXT = "text",
    QUICK_REPLY = "quickReply",
    MENU = "menu",
    IMAGE = "image",
    VIDEO = "video",
    DOCUMENT = "document",
    AUDIO = "audio",
    LOCATION = "location",
    EMAIL = "email",
    TEMPLATE = "template",
    MEDIA = "media"
}

export enum ReplyMediaSettings {
    AUDIO = "audio",
    IMAGE = "image",
    VIDEO = "video",
    DOCUMENT = "document",
}

export enum HttpRequestHeaderType {
    ADD = "add",
    EDIT = "edit"
}

export enum typeVariable {
    STRING = "string",
    NUMBER = "number",
    BOOLEAN = "boolean",
    OBJECT = "object",
    ARRAY = "array"
}

export enum typeResponseTemplate {
    RESPONSE_ERROR = "responseErrorTemplate",
    RESPONSE_BODY = "responseBodyTemplate"
}
export enum typeResponseName {
    ERROR_NAME = "responseErrorName",
    BODY_NAME = "responseBodyName"
}

export enum DataProcessingFieldName {
    ITEM_KEY = "itemKey",
    INDEX_KEY = "indexKey",
    DEST_ARRAY_KEY = "destArrayKey",
    SOURCE_ARRAY_KEY = "sourceArray"
}

export enum TypesFormat {
    DATE = "date",
    NUMBER = "number",
}

export enum typeTime {
    TO = "to",
    FROM = "from"
}

export enum typeOperation {
    ADD = "add",
    DELETE = "delete"
}

export enum optionsName {
    IS_USED_END_CONSITION = "isUsedEndCondition",
    IS_USED_END_WAIT_RES = "isUsedEndWaitRes",
    IS_USED_END_HTTP = "isUsedEndHttp"
}

export enum  endConditionName{
    HTTP_REQUEST = "endHttpRequestId",
    WAIT_RES = "endWaitResId",
    CONDITION = "endConditionId",
    MAP="targetMapNodeId"
}

export enum endPointName {
    HTTP_REQUEST = "endPointIdHttpRequest",
    WAIT_RES = "endPointIdWaitRes",
    CONDITION = "endPointId",
    MAP="endMapId"
}

export enum httpNodeResponseVars {
    BODY = "responseBodyVars",
    ERROR = "responseErrorVars"
}
export enum errorFieldsKeyReplyNode {
    BUTTONS = "buttons",
    CTAS = "ctas",
    NEW_BUTTON = "addNewButton",
    NEW_CTA = "addNewCta",
}

export enum formatNodeKeys {
    DEST_KEY = "destKey",
    SRC_KEY = "srcKey"
}