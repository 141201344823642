import { filter, find, isEmpty, map } from "lodash";
import {
  agentProps,
  updateGroupAgentProps,
  updatePermissionAgentProps,
} from "./type";

export const updatePermissions = (
  agents: agentProps[],
  data: updatePermissionAgentProps
) => {
  const channelPermissions: {permissionId:string,sessionType:string}[] =
    find([...agents], (item: agentProps) => item.agentId===data?.agent_id)
      ?.channelPermissions || [];

  const newPermissions:any= filter(
    [...channelPermissions],
    (item: {permissionId:any,sessionType:string,channelID:any}) => !(data.permission_not_included||"").includes(item.permissionId)
  );
  // map(data?.permissions_included, (item:any) => {
   
  //   let res=find(newPermissions,(i)=>i?.permissionId==item)||""
  //   if(isEmpty(res)){
  //     newPermissions.push({ permissionId: item, sessionType: item, channelID:data?.channelID });
  //   }
  // });
  return newPermissions;
};

export const updateGroups = (
  agents: agentProps[],
  data: updateGroupAgentProps
) => {
  const groups: {groupID:string}[] =
    find([...agents], (item: agentProps) => item.agentId===data?.agent_id)
      ?.groups || [];

  const newGroups = filter(
    [...groups],
    (item) => !data.groups_not_included.includes(item.groupID)
  );

  map(data?.groups_included, (item) => {
    newGroups.push({ groupID: item });
  });

  return newGroups;
};
