import { AxiosResponse } from "axios";
import { VariantType } from "notistack";
import {
  TRIGGER_ALERT,
  REMOVE_ALERT,
  LOADING,
  STOP_LOADING,
  SET_NAV_HEIGHT,
  APP_UPDATED,
  LOADING_MEDIA,
} from "./types";
import mediaService from "services/media.service";
import { errorHandling } from "utils/errorHandling";

export const triggerAlert =
  (msg: string, variant: VariantType) => (dispatch: any) => {
    return dispatch({
      type: TRIGGER_ALERT,
      payload: { msg, variant },
    });
  };

export const removeAlert = (duration?: number) => (dispatch: any) => {
  return dispatch({
    type: REMOVE_ALERT,
    payload: duration,
  });
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const setNavHeight = (height: number) => (dispatch: any) => {
  return dispatch({
    type: SET_NAV_HEIGHT,
    payload: height,
  });
};
export const setAppUpdated = (updated: boolean) => (dispatch: any) => {
  return dispatch({
    type: APP_UPDATED,
    payload: updated,
  });
};
export const loadingUploadMedia = (loading: boolean) => (dispatch: any) => {
  return dispatch({
    type: LOADING_MEDIA,
    payload: loading
  });
};
export const uploadMedia = (file: File, isCallingLoader: boolean = true) => async (dispatch: any) => {
  isCallingLoader && dispatch(loadingUploadMedia(true));
  try {
    const mediaData = new FormData();
    mediaData.append("media", file);

    const mediaResponse: AxiosResponse = await mediaService.uploadMedia(
      mediaData
    );
    isCallingLoader && dispatch(loadingUploadMedia(false));
    return mediaResponse?.data?.url || ""
  } catch (er: any) {
    errorHandling(er, dispatch, "massage_failed");
    dispatch(loadingUploadMedia(false));
  }
};


