export enum SessionEngine {
  none = "",
  facebookDM = "facebook/dm",
  telegramDM = "telegram/message",
  twitter = "twitter",
  twitterDM = "twitter/dm",
  twitterReplay = "twitter/reply",
  twitterTweet = "twitter/tweet",
  twitterMention = "twitter/mention",
  twitterRetweet = "twitter/retweet",
  twitterPost = "twitter/post",
  twitterQuote = "twitter/quote",
  instagram = "instagram",
  instagramDM = "instagram/dm",
  instagramPost = "instagram/post",
  instagramReplay = "instagram/reply",
  instagramComment = "instagram/comment",
  whatsapp = "whatsapp",
  whatsappODA = "oda/whatsapp",
  whatsappVonage = "vonage/whatsapp",
  whatsappMessage = "message/whatsapp",
  whatsapp_ODA_HSM = "oda/whatsapp/hsm",
  email = "email",
  linkedin = "linkedIn",
  linkedinPost = "linkedin/post",
  linkedinMention = "linkedin/mention",
  linkedinReply = "linkedin/reply",
  linkedinComment = "linkedin/comment",
  directMsg = "directMsg",
  messanger = "messanger",
  googleReview = "google_business/review",
  googleMention = "google_business/question",
  googleLocation = "google_business/location",
  youtubeComent = "youtube/comment",
  youtubeVideo = "youtube/video",
  youtubeReply = "youtube/reply",
  /* webChat = "website/chatbot", */
  webchat = "webchat",
  livechat = "livechat",
  outlookEmail = "outlook/email",
  mainEmail = "mail/email",
  gmailEmail = "gmail/email",
  microsoft365Email = "m365/email",
}
