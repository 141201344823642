import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import { ReactComponent as GoodSignal } from "../../assets/network/signal-good.svg";
import { ReactComponent as MediumSignal } from "../../assets/network/medium-signal.svg";
import { ReactComponent as BadSignal } from "../../assets/network/bad-signal.svg";
import { ReactComponent as LostSocket } from "../../assets/network/socket-no-connect.svg";
import { AppConfig } from "enums/config";
import { socket } from "socketConfig/socketConnect";
import { socketConnected, socketDisconnected } from "socketConfig/socketEvent";

interface Props {}

const InternetSpeed = (props: Props) => {
  const [interntSpeed, setInterntSpeed] = useState("");
  const [socketConnected, setSocketStatus] = useState<boolean>(false);

  const testNetwork: () => void = useCallback(() => {
    const userImageLink = `${process.env.REACT_APP_HOST}/img/email.png`;
    let time_start = 0;
    let end_time = 0;

    // The size in bytes
    const downloadSize = 5616998;
    const downloadImgSrc: HTMLImageElement = new Image();

    downloadImgSrc.onload = function () {
      end_time = new Date().getTime();
      // console.log({userImageLink})
      displaySpeed();
    };
    time_start = new Date().getTime();
    downloadImgSrc.src = userImageLink;

    function displaySpeed() {
      const timeDuration = (end_time - time_start) / 1000;
      const loadedBits = downloadSize * 8;

      /* Converts a number into string
      using toFixed(2) rounding to 2 */
      const bps: any = (loadedBits / timeDuration).toFixed(2);
      const speedInKbps: any = (bps / 1024).toFixed(2);
      const speedInMbps = Number((speedInKbps / 1024).toFixed(2));
      const status =
        speedInMbps < 20 ? "slow" : speedInMbps < 60 ? "medium" : "fast";
      // console.log({status,speedInMbps})
      setInterntSpeed(status);
    }
  }, [interntSpeed]);

  useLayoutEffect(() => {
    socket.on("disconnect", function () {
      setSocketStatus((prev) => socket.connected);
    });
    socket.on("connect", function () {
      setSocketStatus((prev) => socket.connected);
    });
    return () => {
      socket.off("disconnect");
    };
  });

  const speedTest = useMemo(
    () =>
      interntSpeed === "slow" ? (
        <BadSignal width="1.5em" height="1.5em" />
      ) : interntSpeed === "medium" ? (
        <MediumSignal width="1.5em" height="1.5em" />
      ) : socket.connected ? (
        <GoodSignal width="1.5em" height="1.5em" />
      ) : (
        <LostSocket width="1.5em" height="1.5em" />
      ),
    [socketConnected]
  );

  return speedTest;
};

export default InternetSpeed;
