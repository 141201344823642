export interface AttachmentList {
  MessageID?: number;
  mimeType?: string;
  fileName?: string;
  url?: string;
  fileType?: string;
  fileSize?: number;
}

export interface Message {
  createAt: Date;
  dateSent: Date;
  message: string;
  messageStatus: string;
  isLiked: boolean;
  messageKey: string;
  replyActive: boolean;
  senderType: string;
  attachmentList: AttachmentList[];
  messageType?: string;
}

export interface Data {
  createAt: Date;
  dateSent: Date;
  message: string;
  isLiked: boolean;
  messageKey: string;
  replyActive: boolean;
  senderType: string;
  messageStatus: string;
  messageType: string;
  subject?:string
  senderKey?:string
}

export interface TwitterData extends Data {
  username: string;
  profileImage: string;
  VendorParentIDs: string;
  senderName: string;
}

export const directMessageLayout: (
  data: Data,
  file: AttachmentList | null | any
) => Message = (data, file) => {
  const {
    createAt,
    dateSent,
    message,
    isLiked,
    messageKey,
    replyActive,
    senderType,
    messageStatus = "SENDING",
    messageType,
    subject,
    senderKey
  } = data;
  let attachmentList: AttachmentList[] = [];
  if (file) {
      if (!Array.isArray(file)) {
          attachmentList = [
              {
                  MessageID: file.MessageID,
                  mimeType: file.mimeType,
                  fileName: file.fileName,
                  url: file.url,
                  fileType: file.fileType,
                  fileSize: file.fileSize,
                },
            ];
        } else if(Array.isArray(file)) {
            attachmentList = file.map((item: AttachmentList) => {
                return {
                    MessageID: item.MessageID,
                    mimeType: item.mimeType,
                    fileName: item.fileName,
                    url: item.url,
                    fileType: item.fileType,
                    fileSize: item.fileSize,
                };
            });
        }
    }
  return {
    createAt,
    dateSent,
    message,
    isLiked,
    messageKey,
    replyActive,
    senderType,
    messageStatus,
    attachmentList,
    messageType,
    ...(subject ? { subject } : {}),
    ...(senderKey ? { senderKey } : {}),
  };
};

export const twitterMessageLayout = (
  data: TwitterData,
  files: AttachmentList[] | null
) => {
  const {
    createAt,
    dateSent,
    message,
    isLiked,
    messageKey,
    replyActive,
    senderType = "agent",
    messageStatus = "SENDING",
    username,
    profileImage,
    VendorParentIDs,
    messageType,
    senderName,
  } = data;

  return {
    createAt,
    dateSent,
    message,
    isLiked,
    messageKey,
    replyActive,
    senderType,
    messageStatus,
    attachmentList: files,
    senderName,
    username,
    profileImage,
    VendorParentIDs,
    messageType,
  };
};
