import { FixedData } from "./type";
import { Theme } from "@mui/material/styles";
import { TFunction } from "react-i18next";
import DashBoardIcon from "../../assets/nav-bar/dashboard.svg";
import CommunityIcon from "../../assets/nav-bar/Community.svg";
import MessageIcon from "../../assets/nav-bar/messagings.svg";
import QuesAnswerIcon from "../../assets/nav-bar/Q&A.svg";
import SettingIcon from "../../assets/nav-bar/setting.svg";
import CampaignIcon from "../../assets/nav-bar/Campaign.svg";
import FlowIcon from "../../assets/nav-bar/flow.svg";
import SubscripeIcon from "../../assets/subscripe/subscripe.svg";
import ReportsIcon from "../../assets/nav-bar/report.svg";
import {
  COMMUNITY_ROUTES,
  FLOW_ROUTES,
  MAIN_ROUTES,
  REPORTS_ROUTES,
  Routes,
  SETTING_ROUTES,
  SUBSCRIPTION_ROUTES,
} from "enums/routes";
import { AppConfig } from "enums/config";

export const fixedNavData: (
  t: TFunction<"translation">,
  theme: Theme,
  minPcMatch: boolean,
  mobileMatch: boolean,
  largnPcMatch: boolean,
  smallPcMatch: boolean,
  pathname: Routes
) => FixedData[] = (
  t,
  theme,
  minPcMatch,
  smallPcMatch,
  largnPcMatch,
  mobileMatch,
  pathname
) => [
  {
    id: 301,
    value: "1",
    titleText: t("dashboard"),
    listItemStyle: {
      opacity: pathname === Routes.DASHBOARD ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      mt: "1em",
      mb: "1em",
      minWidth: largnPcMatch ? "auto" : "90px",
      fontSize: largnPcMatch ? "0.7rem" : "0.85rem",
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
      "&:before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "0.5em",
        bgcolor:
          pathname === Routes.DASHBOARD
            ? theme.palette.general.white
            : "transparent",
        left: "0px",
        borderRadius: `0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX} 0`,
      },
    },
    svgIcon: DashBoardIcon,
    svgStyle: {
      width: "1.5em",
    },
  },
  {
    id: 302,
    value: "2",
    titleText: t("message"),
    listItemStyle: {
      justifyContent: "center",
      opacity: pathname === Routes.CHAT ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      mt: "1em",
      mb: "1em",
      minWidth: largnPcMatch ? "auto" : "90px",
      fontSize: largnPcMatch ? "0.7rem" : "0.85rem",
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
      "&:before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "0.5em",
        bgcolor:
          pathname === Routes.CHAT
            ? theme.palette.general.white
            : "transparent",
        left: "0px",
        borderRadius:`0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX} 0`,
      },
    },
    svgIcon: MessageIcon,
    svgStyle: {
      width: "1.5em",
    },
  },
  {
    id: 303,
    value: "3",
    titleText: t("community"),
    listItemStyle: {
      justifyContent: "center",
      opacity: pathname === Routes.COMMUNITY ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      mt: "1em",
      mb: "1em",
      minWidth: largnPcMatch ? "auto" : "90px",
      fontSize: largnPcMatch ? "0.7rem" : "0.85rem",
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
      "&:before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "0.5em",
        bgcolor:
          pathname === Routes.COMMUNITY
            ? theme.palette.general.white
            : "transparent",
        left: "0px",
        borderRadius: `0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX} 0`,
      },
    },
    svgIcon: CommunityIcon,
    svgStyle: {
      width: "1.5em",
    },
  },
  {
    id: 304,
    value: "4",
    titleText: t("q&a"),
    listItemStyle: {
      justifyContent: "center",
      opacity: COMMUNITY_ROUTES.includes(pathname) ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      mt: "1em",
      mb: "1em",
      minWidth: largnPcMatch ? "auto" : "90px",
      fontSize: largnPcMatch ? "0.7rem" : "0.85rem",
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
      "&:before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "0.5em",
        bgcolor: COMMUNITY_ROUTES.includes(pathname)
          ? theme.palette.general.white
          : "transparent",
        left: "0px",
        borderRadius: `0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX} 0`,
      },
    },
    svgIcon: QuesAnswerIcon,
    svgStyle: {
      width: "3.2em",
    },
  },
  {
    id: 305,
    value: "5",
    titleText: t("campaign"),
    listItemStyle: {
      justifyContent: "center",
      opacity: pathname === Routes.CAMPAIGN ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      mt: "1em",
      mb: "1em",
      minWidth: largnPcMatch ? "auto" : "90px",
      fontSize: largnPcMatch ? "0.7rem" : "0.85rem",
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
      "&:before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "0.5em",
        bgcolor:
          pathname === Routes.CAMPAIGN
            ? theme.palette.general.white
            : "transparent",
        left: "0px",
        borderRadius: `0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX} 0`,
      },
    },
    svgIcon: CampaignIcon,
    svgStyle: {
      width: "2.2",
    },
  },
  {
    id: 307,
    value: "7",
    titleText: t("flow"),
    listItemStyle: {
      justifyContent: "center",
      opacity: FLOW_ROUTES.includes(pathname) ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      mt: "1em",
      mb: "1em",
      minWidth: largnPcMatch ? "auto" : "90px",
      fontSize: largnPcMatch ? "0.7rem" : "0.85rem",
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
      "&:before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "0.5em",
        bgcolor: FLOW_ROUTES.includes(pathname)
          ? theme.palette.general.white
          : "transparent",
        left: "0px",
        borderRadius: `0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX} 0`,
      },
    },
    svgIcon: FlowIcon,
    svgStyle: {
      width: "2.2",
    },
  },

  {
    id: 306,
    value: "6",
    titleText: t("setting"),
    listItemStyle: {
      justifyContent: "center",
      opacity: SETTING_ROUTES.includes(pathname) ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      mt: "1em",
      mb: "1em",
      minWidth: largnPcMatch ? "auto" : "90px",
      fontSize: largnPcMatch ? "0.7rem" : "0.85rem",
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
      "&:before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "0.5em",
        bgcolor: SETTING_ROUTES.includes(pathname)
          ? theme.palette.general.white
          : "transparent",
        left: "0px",
        borderRadius: `0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX} 0`,
      },
    },
    svgIcon: SettingIcon,
    svgStyle: {
      width: "1.5em",
    },
  },
  {
    id: 308,
    value: "8",
    titleText: t("reports"),
    listItemStyle: {
      justifyContent: "center",
      opacity: REPORTS_ROUTES.includes(pathname) ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      mt: "1em",
      mb: "1em",
      minWidth: largnPcMatch ? "auto" : "90px",
      fontSize: largnPcMatch ? "0.7rem" : "0.85rem",
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
      "&:before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "0.5em",
        bgcolor: REPORTS_ROUTES.includes(pathname)
          ? theme.palette.general.white
          : "transparent",
        left: "0px",
        borderRadius: `0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX} 0`,
      },
    },
    svgIcon: ReportsIcon,
    svgStyle: {
      width: "2.2",
    },
  },
  {
    id: 309,
    value: "9",
    titleText: t("subscription"),
    listItemStyle: {
      justifyContent: "center",
      opacity: SUBSCRIPTION_ROUTES.includes(pathname) ? 1 : 0.5,
      color: `${theme.palette.general.white} !important`,
      mt: "1em",
      mb: "1em",
      minWidth: largnPcMatch ? "auto" : "90px",
      fontSize: largnPcMatch ? "0.7rem" : "0.85rem",
      "&:hover": { bgcolor: theme.palette.primary.light, opacity: 1 },
      "&:before": {
        content: '""',
        position: "absolute",
        height: "100%",
        width: "0.5em",
        bgcolor: SUBSCRIPTION_ROUTES.includes(pathname)
          ? theme.palette.general.white
          : "transparent",
        left: "0px",
        borderRadius: `0 ${AppConfig.BORDER_RADIUS_MAX} ${AppConfig.BORDER_RADIUS_MAX} 0`,
      },
    },
    svgIcon: SubscripeIcon,
    svgStyle: {
      width: "1.5em",
    },
  },
];

// convert main routes arr to obj
export const valuesUrl: any = MAIN_ROUTES.reduce(
  (a, v, i) => ({ ...a, [++i]: v }),
  {}
);
