import http, { signal } from "../config/httpComman";

interface userAuth {
  username: string;
  password: string;
  remember: boolean;
  deviceFingerprint: string;
}

export interface signUp {
    adminEmail:string;
    adminPassword:string;
    companyName:string;
    planID: number;
    agreeTerm?:boolean
}

export interface verifySignUp {
    email:string;
    code:string;
}
export default class authService {

    static async postLogin(user : userAuth) {
        return await http.post("/login" , user)
    }

    static async getProfile() {
        return await http.get("/profile",signal)
    }

    static async signUp(data:signUp) {
        return await http.post("/signUp",data)
    }

    static async verifySignup(data:verifySignUp) {
        return await http.post("/verifyEmail",data)
    }

    static async forgetPassword(email:string){
        return await http.post('/forgotPassword',{email})
    }

    static async resetPassword(data:{password:string,
        resetKey:string}){
        return await http.post('/resetPassword ',data)
    }
}
