import { AppContext } from 'App'
import { valuesUrl } from 'components/pageContainer/fixedData'
import useRole from 'hooks/useRole'
import React, { useContext } from 'react'
import {Redirect} from 'react-router-dom'
type Props = {
  role:string[]
}

const AdminWrapper:React.FC<Props> = ({children,role}) => {
    const roleCheck = useRole(role)
    const [t,,,,,,,tabValue,,,,] = useContext(AppContext);
  return (
    <>
    {roleCheck ? children: <Redirect to={valuesUrl[`${tabValue}`]}/>}
    </>
  )
}

export default AdminWrapper