import { UserRoles } from "enums/roles";
import { createSelector } from "reselect";

export interface UserProfile {
  profileStatus: boolean;
  relogin: boolean;
  loginStatue: object;
  socketId: string;
  profile: Profile;
  token: string;
  sigup: boolean;
  verified: boolean;
  verifyEmail: string;
  anotherSignOut: boolean;
}

export interface Profile {
  agentKey: string;
  maxActive: number;
  name: string;
  imageURL: string;
  permissions: string[];
  groups: { groupID: string; groupName: string }[];
  role: UserRoles;
  company: string;
  visibility: 0 | 1 | 2;
}
interface RootState {
  auth: UserProfile;
}

// This used to memorize the selector value
export const authSelector = createSelector(
  (state: RootState) => state.auth,
  (auth: UserProfile) => {
    return {
      loginStatue: auth.loginStatue,
      socketId: auth.socketId,
      profile: auth.profile,
      token: auth.token,
      sigup: auth.sigup,
      verified: auth.verified,
      verifyEmail: auth.verifyEmail,
      anotherSignOut: auth.anotherSignOut,
      relogin: auth.relogin,
      profileStatus: auth.profileStatus,
    };
  }
);
