import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";
import rootreducers from "./reducers";

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootreducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

/* const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  reducer: rootreducers,

  preloadedState: initialState,
}); */

export default store;
