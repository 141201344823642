import React, { useContext } from "react";
import clsx from "clsx";
import { styled } from "@mui/system";
import UseSwitchProps, { useSwitch } from "@mui/base/SwitchUnstyled";
import { AppContext } from "App";
import { AppConfig } from "enums/config";

const SwitchRoot = styled("span")(
  ({}) => `
  display: inline-block;
  position: relative;
  padding: 0x;
`
);

const SwitchInput = styled("input")`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const SwitchThumb = styled("span")(
  ({ theme }) => `
  position: absolute;
  display: flex;
  border-radius: ${AppConfig.BORDER_RADIUS_MIN};
  top: 4.5px;
  left: 12px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  line-height:1.6;
  padding-top: 0px;
  
  &:before {
    display: block;
    content: "${theme.direction === "ltr" ? "Online" : "متاح"}";
    font-family: 'IBM Plex Sans Arabic';
    background-color: ${theme.palette.success.main};
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: ${AppConfig.BORDER_RADIUS_MIN};
    color:${theme.palette.general.offWhite};
    margin-left:-7px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  }
  &:after {
    display: block;
    content: "${theme.direction === "ltr" ? "Offline" : "غير متاح"}";
    font-family: 'IBM Plex Sans Arabic';
    width: 100%;
    height: 100%;
    text-align: center;
    color:${theme.palette.general.grayDark};
  }

  &.focusVisible {
    background-color: #79B;
  }

  &.checked {
    transform: translateX(1em);
    &:before {
      display: block;
      content: "${theme.direction === "ltr" ? "Online" : "متاح"}";
      font-family: 'IBM Plex Sans Arabic';
      width: 100%;
      height: 100%;
      text-align: center;
      color:${theme.palette.general.grayDark};
      background-color:transparent;
      border-radius: ${AppConfig.BORDER_RADIUS_MIN};
    }
    &:after {
        display: block;
        content: "${theme.direction === "ltr" ? "Offline" : "غير متاح"}";
        font-family: 'IBM Plex Sans Arabic';
        background-color: ${theme.palette.error.main};
        border-radius: ${AppConfig.BORDER_RADIUS_MIN};
        width: 100%;
        height: 100%;
        text-align: center;
        box-shadow: none;
        color:${theme.palette.general.offWhite};
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
        margin-right:-9.5px;
    }
  }
`
);

const SwitchTrack = styled("span")(
  ({ theme }) => `
  background-color: ${theme.palette.general.white};
  border:1px solid ${theme.palette.general.grayDark};
  border-radius: ${AppConfig.BORDER_RADIUS_MIN};
  width: 100%;
  height: 100%;
  display: block;
`
);

interface Props {}

const CustomSwitch: React.FC<Props & any> = (props) => {
  const [, theme, minPcMatch, , largPcMatch] = useContext(AppContext);
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);
  const stateClasses = {
    checked,
    disabled,
    focusVisible,
  };

  return (
    <SwitchRoot
      className={clsx(stateClasses)}
      sx={{
        width: "9em",
        height: "34px",
        mr: minPcMatch ? "1em" : 0,
      }}
    >
      <SwitchTrack>
        <SwitchThumb
          className={clsx(stateClasses)}
          sx={{
            width: "7.5em",
            height: "25px",
            borderRadius: "12px",
            left: checked ? "-7px" : "12px",
            "&:before": {
              boxShadow: 0,
            },
          }}
        />
      </SwitchTrack>
      <SwitchInput {...getInputProps()} aria-label="Online Switch" />
    </SwitchRoot>
  );
};

export default CustomSwitch;
