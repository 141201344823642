import {
  LOGIN_STATUS,
  GET_PROFILE,
  ADD_TOKEN,
  USER_SIGNUP,
  USER_VERIFY,
  RESET_REGIST,
  OTHER_LOGOUT,
  DISABLE_CURRENT_TAB_SESSION,
  UPDATE_PROFILE_STATUE,
} from "./types";

interface IntialState {
  loginStatue: {};
  socketId: string;
  profile: { maxActive?: number; name?: string; imageURL?: string };
  token: string;
  sigup: boolean;
  verified: boolean;
  verifyEmail: string;
  anotherSignOut: boolean;
  relogin: boolean;
  profileStatus: boolean;
}

const initialState: IntialState = {
  loginStatue: {},
  socketId: "",
  profile: {},
  token: "",
  sigup: false,
  verified: false,
  verifyEmail: "",
  anotherSignOut: false,
  relogin: false,
  profileStatus: true,
};

export default function authReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_STATUS:
      return {
        ...state,
        loginStatue: payload.loginStatus,
        socketId: payload.socketId,
      };
    case GET_PROFILE:
      return {
        ...state,
        profile: payload.profile,
        profileStatus: payload.profile.visibility !== 2 ? false : true,
      };
    case ADD_TOKEN:
      return {
        ...state,
        token: payload.token,
      };
    case USER_SIGNUP:
      return {
        ...state,
        sigup: true,
        verifyEmail: payload.adminEmail,
      };

    case USER_VERIFY:
      return {
        ...state,
        verified: true,
      };
    case RESET_REGIST:
      return {
        ...state,
        sigup: false,
        verifyEmail: "",
      };
    case OTHER_LOGOUT:
      return {
        ...state,
        anotherSignOut: true,
      };
    case DISABLE_CURRENT_TAB_SESSION:
      return {
        ...state,
        relogin: true,
      };
    case UPDATE_PROFILE_STATUE:
      return {
        ...state,
        profileStatus: payload,
      };
    default:
      return state;
  }
}
