import { AxiosResponse } from "axios";
import {
  GET_PLANS,
  GET_CARDS,
  SUBSCRIBE_PLAN,
  WALLET_BALANCE,
  TRANSACTION_GET,
  DELETE_CARD,
  UNSUBSCRIBE_PLAN,
  SET_DEFAULT_CARD,
  ADD_NEW_CARD,
  UPDATE_SUBSCRIBE_PLAN,
  WALLET_CHARGE,
  TRANSACTION_FILTER,
  RESET_TRANSACTION_FILTER,
  INSERT_WALLET_CONFIG,
  UPDATE_WALLET_CONFIG,
  GET_WALLET_CONFIG,
} from "./types";
import localize from "../../utils/localize";
import { loading, stopLoading, triggerAlert } from "../appSetting/actions";
import SubscribeService from "services/subscribe.service";
import { PlanSubscribe, WalletConfig } from "./type";
import { errorHandling } from "utils/errorHandling";

export const getPalns = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await SubscribeService.getPlans();
    if (response?.data?.failMsg) {
      return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
    }
    dispatch({
      type: GET_PLANS,
      payload: response.data,
    });
  } catch (er:any) {
    errorHandling(er,dispatch,"get_plans_fail")
  } finally {
    dispatch(stopLoading());
  }
};

export const getCards = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await SubscribeService.getCards();
    if (response?.data?.failMsg) {
      return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
    }
    dispatch({
      type: GET_CARDS,
      payload: JSON.parse(response.data),
    });
  } catch (er:any) {
    errorHandling(er,dispatch,"get_cards_fail")
  } finally {
    dispatch(stopLoading());
  }
};

export const subscrubePlan = (data: PlanSubscribe) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await SubscribeService.subscrubePlan(data);
    if (response?.data?.failMsg) {
      return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
    }
    let res = JSON.parse(response.data)
    dispatch({
      type: SUBSCRIBE_PLAN,
      payload: {id:data.planID,amount:data.amount,type:data.paymentType,walletBalance:res.walletBalance},
    });
    dispatch(triggerAlert(localize("plan_subscribe_success"), "success"));
  } catch (er:any) {
    errorHandling(er,dispatch,"plan_subscribe_fail")
  } finally {
    dispatch(stopLoading());
  }
};

export const updateSubscrubePlan =
  (data: PlanSubscribe) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse =
        await SubscribeService.changeSubscrubePlan(data);
      if (response?.data?.failMsg) {
        return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
      }
    //   console.log({res:JSON.parse(response.data)})
      let res = JSON.parse(response.data)
      dispatch({
        type: UPDATE_SUBSCRIBE_PLAN,
        payload: {id:data.planID,amount:data.amount,type:data.paymentType,walletBalance:res.walletBalance},
      });
      dispatch(
        triggerAlert(localize("update_plan_subscribe_success"), "success")
      );
    } catch (er:any) {
        errorHandling(er,dispatch,"update_plan_subscribe_fail")
    } finally {
      dispatch(stopLoading());
    }
  };

export const getWallet = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await SubscribeService.getWalletBalance();
    if (response?.data?.failMsg) {
      return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
    }
    dispatch({
      type: WALLET_BALANCE,
      payload: JSON.parse(response.data),
    });
  } catch (er:any) {
    errorHandling(er,dispatch,"get_balance_fail")
  } finally {
    dispatch(stopLoading());
  }
};

export const getTransactions =
  (data: string = "") =>
  async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await SubscribeService.getTransaction(
        data
      );
      if (response?.data?.failMsg) {
        return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
      }
      dispatch({
        type: TRANSACTION_GET,
        payload: JSON.parse(response.data),
      });
    } catch (er:any) {
        errorHandling(er,dispatch,"get_transaction_fail")
    } finally {
      dispatch(stopLoading());
    }
  };

  export const filterTransactions =
  (data: string = "") =>
  async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await SubscribeService.filterTransaction(
        data
      );
      if (response?.data?.failMsg) {
        return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
      }
      dispatch({ 
        type: TRANSACTION_FILTER,
        payload: JSON.parse(response.data),
      });
    } catch (er:any) {
        errorHandling(er,dispatch,"filter_transaction_fail")
    } finally {
      dispatch(stopLoading());
    }
  }


  export const resetFilterTransactions =
  (data: string = "") =>
  async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await SubscribeService.getTransaction(
        data
      );
      if (response?.data?.failMsg) {
        return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
      }
      dispatch({ 
        type: RESET_TRANSACTION_FILTER,
        payload: JSON.parse(response.data),
      });
    } catch (er:any) {
        errorHandling(er,dispatch,"reset_filter_transaction_fail")
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteCardInfo = (id: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await SubscribeService.deletCard(id);
    if (response?.data?.failMsg) {
      return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
    }
    dispatch({
      type: DELETE_CARD,
      payload: id,
    });
    dispatch(triggerAlert(localize("delete_card_success"), "success"));
  } catch (er:any) {
    errorHandling(er,dispatch,"delete_card_fail")
  } finally {
    dispatch(stopLoading());
  }
};

export const makeCardDefault =
  ({ oldId, id }: { oldId: string; id: string }) =>
  async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await SubscribeService.setDefaultCard(id);
      if (response?.data?.failMsg) {
        return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
      }
          dispatch({
              type: SET_DEFAULT_CARD,
              payload: { id, oldId },
            });
      dispatch(triggerAlert(localize("set_default_card_success"), "success"));
    } catch (er:any) {
        errorHandling(er,dispatch,"set_default_card_fail")
    } finally {
      dispatch(stopLoading());
    }
  };

export const addNewCard = (data: any) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await SubscribeService.addNewCard(data);
    if (response?.data?.failMsg) {
      return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
    }
    let resData = JSON.parse(response.data);
    dispatch({
      type: ADD_NEW_CARD,
    });
    dispatch(triggerAlert(localize("add_card_success"), "success"));
    window.location.href = resData.verficationUrl;
  } catch (er:any) {
    errorHandling(er,dispatch,"add_card_fail")
  } finally {
    dispatch(stopLoading());
  }
};

export const addWalletBalance = (data: any) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await SubscribeService.chargeWalletBalance(data);
      if (response?.data?.failMsg) {
        return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
      }
      let resData = JSON.parse(response.data);
      dispatch({
        type: WALLET_CHARGE,
        payload:data
      });
      dispatch(triggerAlert(localize("charge_wallet_success"), "success"));
    } catch (er:any) {
        errorHandling(er,dispatch,"wallet_insert_config_fail")
    } finally {
      dispatch(stopLoading());
    }
  };

export const planUnsubsribe = (id: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await SubscribeService.unsubscripe(id);
    if (response?.data?.failMsg) {
      return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
    }
    dispatch({
      type: UNSUBSCRIBE_PLAN,
      payload: id,
    });
    dispatch(triggerAlert(localize("unsubscribe_plan_success"), "success"));
  } catch (er:any) {
    errorHandling(er,dispatch,"wallet_insert_config_fail")
  } finally {
    dispatch(stopLoading());
  }
};



export const insertWalletConfig = (data:WalletConfig) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await SubscribeService.walletConfigInsert(data);
      if (response?.data?.failMsg) {
        return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
      }
      dispatch({
        type: INSERT_WALLET_CONFIG,
        payload: JSON.parse(response.data).actions,
      });
      dispatch(triggerAlert(localize("wallet_insert_config_success"), "success"));
    } catch (er:any) {
        errorHandling(er,dispatch,"wallet_insert_config_fail")
    } finally {
      dispatch(stopLoading());
    }
  };


  export const updateWalletConfig = (data:WalletConfig) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await SubscribeService.walletConfigUpdate(data);
      if (response?.data?.failMsg) {
        return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
      }
      dispatch({
        type: UPDATE_WALLET_CONFIG,
        payload: JSON.parse(response.data).actions,
      });
      dispatch(triggerAlert(localize("wallet_update_config_success"), "success"));
    } catch (er:any) {
        errorHandling(er,dispatch,"wallet_update_config_fail")
    } finally {
      dispatch(stopLoading());
    }
  };

  export const getWalletConfig = () => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await SubscribeService.walletConfigGet();
      if (response?.data?.failMsg) {
        return dispatch(triggerAlert(localize(response.data.failMsg), "error"));
      }
      dispatch({
        type: GET_WALLET_CONFIG,
        payload: JSON.parse(response.data),
      });
    } catch (er) {
        errorHandling(er,dispatch,"wallet_get_config_fail")
    } finally {
      dispatch(stopLoading());
    }
  };

